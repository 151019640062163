/* MAIN */
/* :root {
  --primary-color: #0e0d27;
  --secondary-color: #fff;
  --tertiary-color: #010045;
  --btn-color: #2d2ae1;
  --btn-border: #8fc9f2;
  --btn-active: #6dc0ff;
} */

:root {
  --default-bg: #fcfbff;

  --primary-color: #d7c6f3;
  --primary-text: #231833;

  --secondary-color: #1d1d1d;
  --secondary-text: #fbf9ff;
  --secondary-color-opacity: #13111857;

  --tertiary-color: #c1c1ff;

  --btn-color: #9a71e0;
  --btn-border: #28193b;
  --btn-active: #34244c;

  --calculator-background: #ffffff;
  --calculator-btn-bg: #9376be;
  --calculator-input-border: #cfcfcfde;

  --cta-btn: #ffc966;
  --cta-btn-dark: #f1b33e;

  --date-container-color: #d7bcff;
}

/* :root {
  --primary-color: #be93ff;
  --secondary-color: #131118;
  --default-bg: #f7f2ff;
  --tertiary-color: #c1c1ff;

  --secondary-color-opacity: #13111857;

  --primary-text: #231833;
  --secondary-text: #f7f2ff;

  --btn-color: #deccff;
  --btn-border: #28193b;
  --btn-active: #34244c;

  --calculator-background: #e7d7ff;
  --calculator-btn-bg: #e7d7ff;

  --date-container-color: #d7bcff;
} */

body {
  background-color: var(--default-bg);
}
