* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  outline: none;
  color: #0e0d27;
}

body {
  width: 100%;
  font-family: "Roboto", sans-serif;
  color: var(--secondary-color);
}

button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.logo img {
  width: 30%;
  height: auto;
  margin: 0 10px;
}

ul li {
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5c4486;
}

video {
  width: 100%;
  border-radius: 14px;
}

input {
  outline: none;
  border: none;
}

.btn {
  font-size: 17px;
  padding: 15px 30px;
  background-color: var(--cta-btn);
  color: var(--secondary-color);
  outline: none;
  border: none;
  border-radius: 12px;
}

@media screen and (max-width: 1500px) {
  .head-sec .stats-section {
    left: 3%;
  }
}

@media screen and (max-width: 1200px) {
  .service-c {
    width: 90%;
    margin: 8% auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    align-items: center;
    color: var(--primary-text);
  }

  .service-c .services {
    display: flex;
    gap: 20px;
  }
  .service-head-text h1 {
    font-size: 80px;
    font-size: bolder;
    margin-bottom: 4%;
  }

  .steps {
    display: none;
  }

  .work-c {
    display: flex;
    flex-direction: column-reverse;
  }

  .head-sec .main-stats {
    position: relative;
    font-size: 15px;
    gap: 10px;
  }

  body .calculator-c {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .calculator-c .calculator {
    padding: 20px 10px;
    width: 90%;
    margin: 0 auto;
  }

  .calculator-text {
    margin-bottom: 4%;
  }

  .calculator .paper-select {
    margin: 10px 0;
  }

  .pw-ip-c {
    text-align: start;
    margin: 10px 0;
  }

  .calen-c {
    text-align: start;
    margin: 10px 0;
  }

  .calculator-text img {
    display: none;
  }

  .footer-about .footer-socials {
    width: 260px;
  }

  .footer-c .copyright {
    font-size: 16px;
    color: var(--secondary-color);
    margin: 50px 0;
  }
}

@media screen and (max-width: 920px) {
  .main-text .together h1 {
    font-size: 50px !important;
    color: var(--secondary-color) !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--btn-color);
    text-shadow: 2px 2px var(--secondary-color), 20px 20px transparent;
  }

  body .navtop {
    display: none;
  }

  body .navbar {
    padding: 10px;
    border: none;
    display: flex;
  }

  body .navbar ul li {
    font-size: 15px !important;
  }

  .head-sec .main .main-text h1 {
    font-size: 40px;
  }

  .head-sec .main {
    display: block;
    position: relative;
    z-index: 2;
  }

  .head-sec .stats-section {
    display: none;
  }

  .head-sec .main .main-img {
    position: absolute;
    z-index: 1;
    width: 20%;
  }

  .service-c .services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    gap: 20px;
  }
  .service-c .service-head-text h1 {
    font-size: 40px;
    font-size: bolder;
    margin-bottom: 4%;
  }
  .service-c .service-head-text p {
    font-size: 17px;
    font-size: bolder;
    margin-bottom: 3%;
  }

  .about-us-c .about-us {
    display: block;
    position: relative;
  }

  .about-us .main-text h1 {
    font-size: 30px;
  }

  .about-us-c .about-us img {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 10%;
    height: auto;
    margin: 0 auto;
    z-index: 1;
  }

  /* REVIEW */
  .review-c .review-head-text h1 {
    font-size: 30px;
  }
  .reviews {
    display: flex;
    flex-direction: column;
  }

  body .calculator-c {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .calculator-c .calculator .btn-tier {
    padding: 12px;
    width: 100%;
    font-size: 14px;
  }

  .calculator-c .calculator .react-datepicker__input-container input {
    padding: 5% 10px;
    font-weight: 600;
    border-radius: 10px;
    font-size: 100%;
    color: var(--primary-text);
    width: 80%;
  }

  .tier {
    margin: 10px 0 !important;
  }

  /* FOOTER */

  .footer-c .footer-about h3 {
    font-size: 25px;
  }

  .footer-about p {
    font-size: 15px;
  }

  .footer-c .footer-r {
    display: flex;
    width: 100% !important;
    margin: 0;
  }

  .footer-c .footer-r p {
    font-size: 15px;
  }

  .paypal h2 {
    font-size: 16px;
    margin: 6px 0;
  }

  .calculator-c .calculator-text h2 {
    font-size: 30px;
  }

  .calculator-c .calculator-text p {
    font-size: 15px;
  }

  .calculator-c .tier .btn-tier {
    padding: 10px;
  }

  .pw-ip-c .pw-c .pw div {
    font-size: 12px;
  }

  .calculator .price-c .order-btn button {
    font-size: 13px;
    padding: 12px;
  }

  .App .testimonials {
    margin: 60px 0;
  }

  .testimonials .service-head-text h1 {
    font-size: 50px;
  }

  .testimonials .reviews {
    grid-template-columns: auto;
    gap: 20px;
    width: 80%;
    margin: 0 auto;
  }

  .review-slider {
    grid-row: 1;
    /* margin: 70px 0; */
  }
  .review-slider button {
    margin-top: 10px !important;
  }

  .reviews .review-head-text {
    margin: 0px 0;
  }
  .reviews .review-head-text h1 {
    font-size: 32px;
  }

  /* .App .footer-c {
    margin: 50px 0;
  } */
}

@media screen and (max-width: 675px) {
  .quantity-c {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .quantity {
    width: 100% !important;
  }

  .react-datepicker__input-container input {
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  body .footer .footer-r {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    font-size: 15px !important;
  }

  .tier {
    gap: 10px !important;
  }

  .calculator {
    width: 100% !important;
  }

  .calculator-c .calculator .tier div p {
    font-size: 12px;
  }

  .footer-c .footer-socials {
    width: 200px;
  }

  .contact .navbar .main-ul {
    gap: 12px !important;
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .main-img {
    display: none;
  }
  .main-img img {
    display: none;
  }

  .calculator-c .calculator .tier {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-content: center;
    /* border: 2px solid var(--primary-color); */
    padding: 8px;
    border-radius: 12px;
  }

  .calculator-c .calculator .tier .btn-tier {
    padding: 7px;
    font-size: 15px;
    border-radius: 5px;
  }

  .calculator-c .calculator .tier .btn-tier:after {
    display: none;
  }

  .main-text-a {
    font-size: 16px !important;
  }

  .footer-c .footer-about .footer-socials img {
    width: 60%;
    height: auto;
  }

  .navbar .logo img {
    width: 50px;
    height: 25px;
  }

  .calculator-c .calculator .quantity-c {
    margin: 0;
  }

  .price h2 {
    font-size: 22px;
  }

  .price-c .order-btn button {
    font-size: 12px !important;
  }

  .main .main-text p {
    font-size: 16px;
  }

  .testimonials .service-head-text h1 {
    font-size: 30px;
  }

  .reviews {
    width: 90% !important;
  }

  .testimonials .service-head-text p {
    font-size: 15px;
  }

  .form-c form {
    padding: 20px 10px !important;
  }

  .form-c form input {
    font-size: 15px !important;
  }

  .review-slider .review-head-text p {
    font-size: 15px !important;
  }

  .review-slider .review-head-text h1 {
    font-size: 22px !important;
  }

  .review .customer div h2 {
    font-size: 20px;
  }

  .review .customer div p {
    font-size: 15px;
  }

  .review p {
    font-size: 16px;
  }
}

@media screen and (max-width: 340px) {
  .calculator-c .calculator {
    border: none;
  }
  .calculator-c .calculator .tier {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-content: center;
    background-color: var(--secondary-color);
    border: 2px solid var(--primary-color);
    padding: 8px;
    border-radius: 12px;
  }
}
